import React, { useState, useEffect } from 'react';
import apsbox from '../../../images/aps_box.png'

import {
  useCartData,
  useMbox,
  useServiceablitityData,
  useSessionData,
} from '../../../hooks/order';
import {
  getHomeUrl,
  getValid$Cost,
  isUndefinedNullOrEmpty,
} from '../../../common/common';
import { useDispatch } from 'react-redux';
import {
  getServicePropsByCode,
  saveVasPlanInfo,
  saveInternetPlan,
  setMBoxReqObj,
  setTFN,
  setSelectedBundle
} from '../../../store/action/OrderAction';
import { vasLearnMoreLinks, routesConfig } from '../../../util/util';
import {
  EECproductAddToCart,
  EECproductDetailView,
  EECproductRemoveFromCart,
} from '../../../common/GTMScripts';
import { Carousel } from 'antd';
import { useNavigate } from 'react-router-dom';

const SECURITY_TOOLS_URL = getHomeUrl() + 'security-privacy/';
const EASY_TECH_URL = getHomeUrl() + 'security-privacy/remote-it-support/';

const VasPrdoduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sessionResponse } = useSessionData();
  const { serviceabilityCheck } = useServiceablitityData();
  const { vasPlanInfo, internetPlan, wirelessCartInfo, selectedBundleData = {}, isBundleAdded } = useCartData();
  const { mboxRequired } = useMbox();
  const { vasProducts } = serviceabilityCheck;
  const [state, setState] = useState({
    vasProductCart: isUndefinedNullOrEmpty(vasPlanInfo.vasProductCart)
      ? []
      : vasPlanInfo.vasProductCart,
    selectedVasInfo: {},
    categories: [],
    vasProductNameCart: isUndefinedNullOrEmpty(vasPlanInfo.vasPlan)
      ? []
      : vasPlanInfo.vasPlan,
    showMore: -1,
  });

  useEffect(() => {
    dispatch(setTFN('ABNDCART'));
    let categories = [];
    if (vasProducts && vasProducts.length > 0) {
      categories = [...new Set(vasProducts.map((v) => v.techDetail))];
      if (categories && categories.length > 0) {
        setState({ ...state, categories: categories });
      }
    }
    if (
      Object.keys(internetPlan).length === 0 &&
      Object.keys(wirelessCartInfo).length === 0
    ) {
      window.location.href = getHomeUrl();
    }
    if (Object.keys(sessionResponse).length === 0) {
      window.location.href = getHomeUrl();
    }
    EECproductDetailView(vasProducts);
  }, []);

  const { vasProductCart, vasProductNameCart } = state;
  let { showMore } = state;

  useEffect(() => {
    if (
      Object.keys(vasPlanInfo).length &&
      vasPlanInfo.vasPlan !== vasProductNameCart
    ) {
      setState({
        ...state,
        vasProductCart: isUndefinedNullOrEmpty(vasPlanInfo?.vasProductCart)
          ? []
          : vasPlanInfo?.vasProductCart,
        vasProductNameCart: isUndefinedNullOrEmpty(vasPlanInfo?.vasPlan)
          ? []
          : vasPlanInfo.vasPlan,
      });
    }
  }, [vasPlanInfo]);

  const addVasToCart = (serviceRef, itemInd) => {
    if (!serviceRef) {
      return;
    }
    let vasValue = 0;
    let vasTechDetail = {};
    let mboxReq = mboxRequired;

    if (vasProducts && vasProducts.length) {
      vasProducts.forEach((item, i) => {
        if (item.servRef === serviceRef) {
          vasValue = i;
          vasTechDetail = item;
        }
      })
    }


    // Already in Cart then splice cart array
    if (isBundleAdded) {
      const { servLevel1 = '', servLevel2 = '', servLevel3 = '', servLevel4 = '' } = selectedBundleData || {};
      if ([servLevel1, servLevel2, servLevel3, servLevel4]?.map(el => el?.toLowerCase())?.includes(vasTechDetail?.servLevel?.toLowerCase())) {
        dispatch(saveInternetPlan({}))
        dispatch(setSelectedBundle(null));
        navigate(routesConfig.products);
      }
    }

    if (vasProductCart.includes(vasValue)) {
      delete mboxReq[vasProducts[vasValue].servCode];
      vasProductCart.splice(vasProductCart.indexOf(vasValue), 1)
      vasProductNameCart.splice(vasProductNameCart.indexOf(vasTechDetail), 1)
      dispatch(saveVasPlanInfo({
        vasPlan: vasProductCart.map(i => serviceabilityCheck.vasProducts[i]),
        vasProductCart,
      }));
      EECproductRemoveFromCart(vasTechDetail)
      dispatch(setMBoxReqObj(mboxReq));
      return
    }

    if (!vasProductNameCart.includes(vasTechDetail)) {
      let presentCategory = false;
      let ind = 0;
      if (vasProductNameCart && vasProductNameCart.length > 0) {
        vasProductNameCart.forEach((v, index) => {
          if (v.techDetail === vasTechDetail.techDetail) {
            presentCategory = true;
            ind = index;
          }
        })
      }
      if (presentCategory) {
        vasProductNameCart.splice(ind, 1, vasTechDetail);
        EECproductRemoveFromCart(vasTechDetail)
      } else {
        if(itemInd === showMore){
          showMore = -1;
        }
        vasProductNameCart.push(vasTechDetail);
        EECproductAddToCart(vasTechDetail)
      }
    }

    // If vas is not in cart, then add to cart array
    if (!vasProductCart.includes(vasValue)) {
      let presentCategory = false;
      let ind = 0;

      // Check if mailbox required for vas product
      // in case of Online backup, ELNK mailbox is required always
      dispatch(getServicePropsByCode(vasProducts[vasValue].servCode)).then((res) => {
        mboxReq[vasProducts[vasValue].servCode] = (res.action?.payload?.data?.item?.isMboxReq);
        dispatch(setMBoxReqObj(mboxReq));
      });

      if (vasProductCart && vasProductCart.length > 0) {
        vasProductCart.forEach((v, index) => {
          if (vasProducts[v].techDetail === vasTechDetail.techDetail) {
            presentCategory = true;
            ind = index;
          }
        })
      }
      if (presentCategory) {
        vasProductCart.splice(ind, 1, vasValue);
      } else {
        vasProductCart.push(vasValue);
      }

      dispatch(saveVasPlanInfo({
        vasPlan: vasProductCart.map(i => serviceabilityCheck.vasProducts[i]),
        vasProductCart,
      }));
    }
    setState({
      ...state,
      vasProductCart,
      vasProductNameCart,
      showMore
    })
  };

  const getLearnMoreLink = (c) => {
    const { protect, guardian, backup } = vasLearnMoreLinks;
    if (c.toLowerCase().includes('protect')) {
      return SECURITY_TOOLS_URL + protect;
    } else if (c.toLowerCase().includes('backup')) {
      return SECURITY_TOOLS_URL + backup;
    } else if (c.toLowerCase().includes('guardian')) {
      return SECURITY_TOOLS_URL + guardian;
    } else if (c.toLowerCase().includes('easytech')) {
      return EASY_TECH_URL;
    } else {
      return '';
    }
  };

  const categoryArray = () => {
    const { categories } = state;
    let categoriesArray = [];
    if (categories && categories.length) {
      categories.forEach((c) => {
        const data =
          vasProducts &&
          vasProducts.length > 0 &&
          vasProducts.filter((v) => {
            return v.techDetail === c;
          });
        const sortedArray = data.slice();
        sortedArray.sort(function (a, b) {
          if (sortedArray[0].techDetail.includes('EarthLink Live TV')) {
            return parseFloat(b.price) - parseFloat(a.price);
          }
          return parseFloat(a.price) - parseFloat(b.price);
        });
        const learnMoreLink = getLearnMoreLink(c);
        let obj = { name: c, products: sortedArray, learnMoreLink };
        categoriesArray.push(obj);
      });
    }
    return categoriesArray;
  };

  const handleVasAddtoCartButton = (e, ind) => {
    const prev = e.target.previousElementSibling;
    const vasSelectedProduct = prev && prev.value;
    if (vasSelectedProduct !== '' && vasSelectedProduct !== null) {
      addVasToCart(vasSelectedProduct, ind);
    }
  };

  const handleReadMore = (i) => {
    setState({
      ...state,
      showMore: state.showMore === i ? -1 : i,
    });
  };

  let categoriesArray = categoryArray();
  let vasCartProductName = '';

  const carouselSettings = {
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          fade: true
        }
      },
    ]

  };

  return (
    <>
      <div className="main_checkout_wrapp">
        <div className="main-checkout label">

          <div className="vas-carousel checkout_vas_carousel_wrapp">
            <h2 className="vas_heading">
              <span className="icn"><img src={apsbox} alt="apsbox" /></span>
              <span className="txt">Additional Products and Services</span>
            </h2>

            <div className="vas-products">
              <div className="vas-main-products">
                <Carousel {...carouselSettings}>
                  {categoriesArray?.length
                    ? categoriesArray.map((data, i) => {
                      let itemsArr = [],
                        learnMoreData = '';
                      if (data?.products?.length) {
                        data.products.forEach((item) => {
                          itemsArr.push({
                            servRef: item.servRef,
                            name: item.serviceName,
                            price: item.price,
                            terms: item.termsOfSrvc,
                          });
                        });
                      }
                      if (vasProductNameCart?.length) {
                        vasProductNameCart.find((v) => {
                          if (data.name === v.techDetail) {
                            vasCartProductName = v.techDetail;
                          }
                        });
                      }

                      // LEARN MORE DATA
                      learnMoreData =
                        data?.products[0]?.serviceInfo
                          ?.split('.')
                          .map((listItem, ind) => {
                            if (listItem !== '') {
                              return (
                                <li key={ind}>{listItem && listItem + '.'}</li>
                              );
                            }
                          });

                      return (
                        <div
                          className={`vas-single-prod  ${showMore === i && showMore !== -1
                            ? 'vas-less'
                            : ''
                          }`}
                          key={i}
                        >
                          <div className="vas-description-col">
                            <h3 className="vas_item_heading">
                              {data.name}
                            </h3>
                            <p className="item-text-set">
                              {' '}
                              {data
                                ?
                                data?.products[0]?.serviceDescription?.split(
                                  '.'
                                )[0] + '.'
                                : null}
                            </p>

                            <ul className="vas_item_list">
                              {showMore === i && showMore !== -1
                                ? learnMoreData
                                : null}
                            </ul>
                            <button
                              className="seemore"
                              onClick={() => {
                                handleReadMore(i);
                              }}
                              onKeyDown={()=>{}}
                            >
                              {' '}
                              {showMore === i && showMore !== -1
                                ? 'See Less'
                                : 'See More'}{' '}
                              <i
                                className={`fa-solid ${showMore === i && showMore !== -1
                                  ? 'fa-angle-up'
                                  : 'fa-angle-down'
                                }`}
                              ></i>
                            </button>
                          </div>
                          {itemsArr?.length &&
                            itemsArr.map((item, index) => {
                              let isDisabled = false;
                              let isProtectInCart = false;
                              let selectedCartProducts = [];
                              if (vasProductNameCart.length) {
                                vasProductNameCart.find((v) => {
                                  if (
                                    [
                                      'EarthLink Protect',
                                      'EarthLink Protect+',
                                    ].includes(v.techDetail)
                                  ) {
                                    isProtectInCart = true;
                                  }
                                  selectedCartProducts.push(v.servRef);
                                  if (v.serviceName === item.name) {
                                    isDisabled = true;
                                    return v;
                                  } else {
                                    return null;
                                  }
                                });
                              }
                              let btnDisabled =
                                [
                                  'EarthLink Protect',
                                  'EarthLink Protect+',
                                ].includes(data.name) && isProtectInCart;
                              return (
                                <>
                                  {itemsArr.length < 2 ? (
                                    <div className="single-vas-text below-selected-part d-flex j-between al-center">
                                      <div className="price_wrapp">
                                        <p>{getValid$Cost(item.price)} / mo</p>
                                      </div>
                                      <div className="btn_wrapp">
                                        <button
                                          className={`blue-button ${isDisabled
                                            ? 'vas-remove-btn grey-button'
                                            : ''
                                          }`}
                                          // disabled={btnDisabled}
                                          onClick={() =>
                                            addVasToCart(item.servRef)
                                          }
                                        >
                                          <i
                                            className={`fa-solid ${isDisabled
                                              ? 'fa-times'
                                              : 'fa-cart-plus'
                                            }`}
                                          ></i>
                                          {isDisabled ? 'Remove' : 'Add'}{' '}
                                        </button>
                                      </div>

                                    </div>
                                  ) : (
                                    index === 0 && (
                                      <div className="below-selected-part d-flex j-between al-center">
                                        <select
                                          placeholder="Select"
                                          className="vas-selection"
                                          dropdownMatchSelectWidth={false}
                                          productName={data.name}
                                          disabled={
                                            data.name ===
                                            vasCartProductName ||
                                            btnDisabled
                                          }
                                        >
                                          <option key={0} value="">
                                            Select
                                          </option>
                                          {itemsArr.map((option) => {
                                            let productName = '';
                                            if (
                                              option?.name?.includes(
                                                'EarthLink Protect'
                                              )
                                            ) {
                                              productName =
                                                option?.name?.replace(
                                                  'EarthLink Protect',
                                                  ''
                                                );
                                            }
                                            if (option?.name?.includes('Protect+')) {
                                              productName =
                                                option?.name?.replace(
                                                  'Protect+',
                                                  ''
                                                );
                                            }
                                            if (
                                              option?.name?.includes(
                                                'Online Backup'
                                              )
                                            ) {
                                              productName =
                                                option?.name?.replace(
                                                  'Online Backup',
                                                  ''
                                                );
                                            }
                                            if (
                                              option?.name?.startsWith(
                                                'EarthLink'
                                              )
                                            ) {
                                              productName =
                                                option?.name?.replace(
                                                  'EarthLink ',
                                                  ''
                                                );
                                            }
                                            // REMOVE PROTECT FROM PRODUCT NAME
                                            if (
                                              productName?.startsWith(
                                                'Protect'
                                              )
                                            ) {
                                              productName =
                                                productName?.replace(
                                                  'Protect',
                                                  ''
                                                );
                                            }
                                            return (
                                              <option
                                                key={option.servRef}
                                                value={option.servRef}
                                                selected={
                                                  selectedCartProducts?.includes(
                                                    option.servRef
                                                  )
                                                }
                                              >{`${productName} - ${getValid$Cost(
                                                  option.price
                                                )} / mo`}</option>
                                            );
                                          })}
                                        </select>
                                        <button
                                          className={`blue-button ${data.name ===
                                            vasCartProductName || isDisabled
                                            ? 'vas-remove-btn grey-button'
                                            : ''
                                          }`}
                                          disabled={
                                            data.name !==
                                            vasCartProductName &&
                                            btnDisabled
                                          }
                                          onClick={(e) =>
                                            handleVasAddtoCartButton(e)
                                          }
                                          productName={data.name}
                                          value={itemsArr[0]?.servRef}
                                        >
                                          <i
                                            className={`fa-solid ${data.name ===
                                              vasCartProductName ||
                                              isDisabled
                                              ? 'fa-times'
                                              : 'fa-cart-plus'
                                            }`}
                                          ></i>
                                          {data.name === vasCartProductName ||
                                            isDisabled
                                            ? 'Remove'
                                            : 'Add'}{' '}
                                        </button>
                                      </div>
                                    )
                                  )}
                                </>
                              );
                            })}
                        </div>
                      );
                    })
                    : null}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VasPrdoduct;
