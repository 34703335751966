import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { getProductPrice, getValid$Cost, vendorInformation, getWirelessServRef, getPromoPrice } from '../../../common/common';
import { useDispatch } from 'react-redux';
import { getServicePropsByCode, saveInternetPlan, setMBoxReqObj, saveVasPlanInfo, setSelectedBundle, saveWirelessPlanInfo, setSelectedProduct } from '../../../store/action/OrderAction';
import { useSessionData, useCallCenterFlag, useCartData, useMbox, useBusinessAddr } from '../../../hooks/order';
import { EECproductAddToCart, EECproductRemoveFromCart } from '../../../common/GTMScripts';
import { RenderTfnButton } from '../../Layout/RenderTfnButton';
import WhiModal from '../../common/WhiModal';
import PropTypes from 'prop-types';


const InternetPlans = ({ serviceabilityCheck, productSpd300, customTFN }) => {
  const dispatch = useDispatch();
  const { plans, serviceTerm, fixedWirelessProducts } = serviceabilityCheck;
  const [showMore, setShowMore] = useState(-1);
  const [showWhiAddedModal, setShowWhiAddedModal] = useState(false);
  const { internetPlan = {}, selectedBundleData, isBundleAdded, selectedProduct } = useCartData();
  const { mboxRequired } = useMbox();
  const isInternetAdded = Object.keys(internetPlan).length > 0;
  const { showCallNow, showPromoStickers } = useCallCenterFlag();
  const { extendedInfo } = useSessionData();
  const { isBusinessAddress } = useBusinessAddr();

  // Add to cart

  const handleAddIntToCart = (product) => {
    dispatch(getServicePropsByCode(product?.servCode)).then((res) => {
      mboxRequired[product.servCode] =
        res.action?.payload?.data?.item?.isMboxReq;
      dispatch(setMBoxReqObj(mboxRequired));
    });
    dispatch(saveInternetPlan(product));
    EECproductAddToCart(product);
  }

  const addIntToCart = (product) => {
    if (isInternetAdded) {
      delete mboxRequired[product.servCode];
      dispatch(setMBoxReqObj(mboxRequired));
      EECproductRemoveFromCart(product)
      dispatch(saveInternetPlan({}))
      if (isBundleAdded && selectedBundleData?.category !== 'mobile') {
        dispatch(saveVasPlanInfo({
          vasPlan: [],
          vasProductCart: []
        }))
        dispatch(saveWirelessPlanInfo({
          wirelessPlanInfo: [],
          wirelesServeRef: getWirelessServRef([])
        }));
        dispatch(setSelectedBundle(null));
      }
    } else if (product.servCode?.toLowerCase() === 'elfw' && !isBusinessAddress) {
      setShowWhiAddedModal(true);
      dispatch(setSelectedProduct(product));
    } else {
      handleAddIntToCart(product);
    }
  };



  let sortedData = [];

  if (plans) {
    sortedData = plans.sort((a, b) => {
      return a.downstreamSpd - b.downstreamSpd
    });
  }

  const showDiscount = (product) => {
    const { downstreamSpd = 0 } = product;
    const vendor = vendorInformation(product, extendedInfo)
    if (vendor.toLowerCase() === 'frontier') {
      if ((downstreamSpd / 1000) === 200) {
        return 'noDiscount'
      } else if ((downstreamSpd / 1000) > 200) {
        return 'moreDiscount'
      } else {
        return ''
      }
    } else if ((vendor.toLowerCase() === 'centurylink') && ((downstreamSpd / 1000) >= 100)) {
      return 'noDiscount'
    } else {
      if ((downstreamSpd / 1000) === 100) {
        return 'Discount'
      } else if ((downstreamSpd / 1000) > 100) {
        return 'Discount'
      } else {
        return ''
      }
    }
  }


  const showMostPopular = (i, product) => {
    const { downstreamSpd = 0 } = product;
    const productSpd = downstreamSpd / 1000 === 300;
    const vendor = vendorInformation(product, extendedInfo);
    if (vendor.toLowerCase() === 'at&t' && productSpd) {
      return 'mostPop';
    }
    if (
      i.toString() ===
      `${showCallNow || showPromoStickers ? sortedData.length - 1 : '0'}` &&
      !productSpd300
    ) {
      return 'mostPop';
    }
    return '';
  };
  const allplansClass = () => {
    if (showPromoStickers) {
      return 'promo-sticker'
    } else if (plans.length) {
      return 'internet-promo'
    }
  }

  return (
    <>
      <div
        className="plan-heading d-flex al-center">
        <span className="y-icon">
          <i className="fa-solid fa-wifi"></i>
        </span>{' '}
        <h3>
          (<span>{sortedData.length + fixedWirelessProducts.length}</span>){' '}
          {!plans.length && fixedWirelessProducts.length > 0
            ? 'Wireless Home'
            : ''}{' '}
          Internet Plan
          {`${plans && plans.length + fixedWirelessProducts.length > 1 ? 's' : ''
          }`}{' '}
          Available
        </h3>
      </div>

      <div
        className={`all-single-plans ${allplansClass()}`}
      >
        <Row>
          {sortedData.map((product, i) => {
            let learnMoreData = '';
            // LEARN MORE DATA
            learnMoreData =
              product &&
              product.serviceDesc
                ?.split('.')
                .filter((item) => item !== '')
                .map((listItem, index) => {
                  if (index > 0) {
                    return (
                      <li key={listItem}>
                        <p key={listItem}>{listItem && listItem + '.'}</p>
                      </li>
                    );
                  }
                  return '';
                });
            return (
              <Col
                span={12}
                className={`plan-single ${showMostPopular(i, product)} ${showCallNow || showPromoStickers ? showDiscount(product) : ''
                }`}
                key={product.servRef}
              >
                <div
                  className={`plan-single-inner  ${showMore === i && showMore !== -1 ? 'Seeless' : ''
                  }`}
                >
                  <h4>
                    {product.downstreamSpd && product.downstreamSpd / 1000} Mbps
                  </h4>
                  <ul>
                    <li>
                      <p>{product.serviceDesc?.split('.')[0]}.</p>
                    </li>
                    {showMore === i && showMore !== -1 ? learnMoreData : null}
                  </ul>
                  <button
                    className="seemore"
                    onClick={() => {
                      setShowMore(showMore === i ? -1 : i);
                    }}
                    onKeyDown={() => { }}
                  >
                    {' '}
                    {showMore === i && showMore !== -1
                      ? 'See Less'
                      : 'See More'}{' '}
                    <i
                      className={`fa-solid ${showMore === i && showMore !== -1
                        ? 'fa-angle-up'
                        : 'fa-angle-down'
                      }`}
                    ></i>
                  </button>
                  {!showCallNow ? (
                    <div
                      className={`down-cart d-flex al-center j-between ${showPromoStickers ? '' : 'show-promo'
                      }`}
                    >
                      <button
                        className={`blue-button ${isInternetAdded ? 'grey-button' : ''
                        }`}
                        disabled={
                          isInternetAdded &&
                          internetPlan.servRef !== product.servRef
                        }
                        onClick={() => {
                          addIntToCart(product);
                        }}
                      >
                        {isInternetAdded &&
                          internetPlan.servRef === product.servRef
                          ? 'Remove'
                          : 'Add'}{' '}
                        <i
                          className={`fa-solid ${isInternetAdded &&
                            internetPlan.servRef === product.servRef
                            ? 'fa-times'
                            : 'fa-cart-plus'
                          }`}
                        ></i>
                      </button>

                      <div className="main-below-total">
                        {getPromoPrice(product, serviceTerm) && (
                          <span className="discount">
                            {getValid$Cost(product.price)}
                          </span>
                        )}
                        <span className="total-single">
                          {getPromoPrice(product, serviceTerm)
                            ? getPromoPrice(product, serviceTerm)
                            : getProductPrice(product, serviceTerm)}
                          / mo
                        </span>
                      </div>
                    </div>
                  ) : (
                    <RenderTfnButton customTFN={customTFN} />
                  )}
                </div>
              </Col>
            );
          })}
          {fixedWirelessProducts &&
            fixedWirelessProducts.length > 0 &&
            fixedWirelessProducts.map((data, i) => {
              let learnMoreData = '';
              // LEARN MORE DATA
              learnMoreData =
                data &&
                data.serviceDescription
                  ?.split('.')
                  .filter((item) => item !== '')
                  .map((listItem, index) => {
                    if (index > 0) {
                      return (
                        <li key={listItem}>
                          <p>{listItem && listItem + '.'}</p>
                        </li>
                      );
                    }
                    return '';
                  });
              return (
                <Col span={12} className="plan-single" key={data.servRef}>
                  <div
                    className={`plan-single-inner wireless-prod  ${showMore === i && showMore !== -1 ? 'Seeless' : ''
                    }`}
                  >
                    <h4>
                      {data.serviceName.replace('plan', '').replace('Plan', '')}{' '}
                      Data
                    </h4>
                    {/* <h5>{getValid$Cost(data.price)} / month</h5> */}
                    <ul>
                      <li>
                        <p>{data.serviceDescription?.split('.')[0]}.</p>
                      </li>
                      {showMore === i && showMore !== -1 ? learnMoreData : null}
                    </ul>
                    <button
                      className="seemore"
                      onClick={() => {
                        setShowMore(showMore === i ? -1 : i);
                      }}
                      onKeyDown={() => { }}
                    >
                      {' '}
                      {showMore === i && showMore !== -1
                        ? 'See Less'
                        : 'See More'}{' '}
                      <i
                        className={`fa-solid ${showMore === i && showMore !== -1
                          ? 'fa-angle-up'
                          : 'fa-angle-down'
                        }`}
                      ></i>
                    </button>
                    {!showCallNow ? (
                      <div className="down-cart d-flex al-center j-between">
                        {/* Call now */}
                        <button
                          className={`blue-button ${isInternetAdded && 'grey-button'
                          }`}
                          disabled={
                            isInternetAdded &&
                            internetPlan.servRef !== data.servRef
                          }
                          onClick={() => {
                            addIntToCart(data);
                          }}
                        >
                          {isInternetAdded &&
                            internetPlan.servRef === data.servRef
                            ? 'Remove'
                            : 'Add'}{' '}
                          <i
                            className={`fa-solid ${isInternetAdded &&
                              internetPlan.servRef === data.servRef
                              ? 'fa-times'
                              : 'fa-cart-plus'
                            }`}
                          ></i>
                        </button>

                        <div className="main-below-total">
                          {getPromoPrice(data, serviceTerm) && (
                            <span className="discount">
                              {getValid$Cost(data.price)}
                            </span>
                          )}
                          <span className="total-single">
                            {getPromoPrice(data, serviceTerm)
                              ? getPromoPrice(data, serviceTerm)
                              : getValid$Cost(data.price)}{' '}
                            / mo
                          </span>
                        </div>
                      </div>
                    ) : (
                      <RenderTfnButton product="whi" />
                    )}
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
      <WhiModal showWhiAddedModal={showWhiAddedModal} setShowWhiAddedModal={setShowWhiAddedModal} selectedProduct={selectedProduct} handleAddIntToCart={handleAddIntToCart} />
    </>
  );
};

InternetPlans.propTypes = {
  serviceabilityCheck: PropTypes.object.isRequired,
  productSpd300: PropTypes.bool.isRequired,
  customTFN: PropTypes.string.isRequired
};

export default InternetPlans;
