import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getValidIntCost, isUndefinedNullOrEmpty, getValid$Cost, getProductPrice, replaceServiceName, getPromoPrice, getWirelessServRef, formatTFN } from '../common/common';
import { getConfigs } from '../config';
import { setSelectedBundle } from '../store/action/OrderAction';
import { TRANSLATE } from '../helpers/OrderHelperFunctions';
import useDNISDK from './dni';
const config = getConfigs();

export const useSessionData = () => {
  const { sessionResponse, orderInfo, TFN, customTFN, extendedInfo, referrer, customWhiTFN, setActiveProductTab } = useSelector(
    (state) => ({
      sessionResponse: state.OrderState.sessionResponse,
      TFN: state.OrderState.TFN,
      orderInfo: state.OrderState.orderInfo,
      customTFN: state.OrderState.customTFN,
      extendedInfo: state.OrderState.extendedInfo,
      referrer: state.OrderState.referrer,
      customWhiTFN: state.OrderState.customWhiTFN,
      setActiveProductTab: state.OrderState.setActiveProductTab
    }),
  );

  return {
    sessionResponse,
    orderInfo,
    TFN,
    customTFN,
    extendedInfo,
    referrer,
    customWhiTFN,
    setActiveProductTab
  };
};

export const useServiceablitityData = () => {
  const { serviceabilityCheck, normalizedAddress, adslShippingCost, scheduleAppt, callLogId, canScheduleAppt, mrcPosProducts, isRtsVisible, rtsAttemptCount } = useSelector(
    (state) => ({
      serviceabilityCheck: state.OrderState.serviceabilityCheck,
      normalizedAddress: state.OrderState.normalizedAddress,
      adslShippingCost: state.OrderState.adslShippingCost,
      scheduleAppt: state.OrderState.scheduleAppt,
      callLogId: state.OrderState.callLogId,
      canScheduleAppt: state.OrderState.canScheduleAppt,
      mrcPosProducts: state.OrderState.mrcPosProducts,
      isRtsVisible: state.OrderState.isRtsVisible,
      rtsAttemptCount: state.OrderState.rtsAttemptCount,
    }),
  );

  return {
    serviceabilityCheck,
    normalizedAddress,
    adslShippingCost,
    scheduleAppt,
    callLogId,
    canScheduleAppt,
    mrcPosProducts,
    isRtsVisible,
    rtsAttemptCount,
  };
}

export const useCartData = () => {
  const { internetPlan, wirelessCartInfo = [], vasPlanInfo, serviceTerm, adslShippingCost, isProductsAdded, errorMessage, isVasOnlyFlow, isMobileContainer, bundlePlan, selectedBundleData, languagePref, generalTerms, selectedProduct, broadbandEmailError } = useSelector(
    (state) => ({
      isProductsAdded: state.OrderState.isProductsAdded,
      internetPlan: state.OrderState.internetPlan,
      wirelessCartInfo: state.OrderState.wirelessCartInfo,
      vasPlanInfo: state.OrderState.vasPlanInfo,
      serviceTerm: state.OrderState.serviceabilityCheck.serviceTerm,
      adslShippingCost: state.OrderState.adslShippingCost,
      errorMessage: state.OrderState.errorMessage,
      isVasOnlyFlow: state.OrderState.isVasOnlyFlow,
      isMobileContainer: state.OrderState.isMobileContainer,
      bundlePlan: state.OrderState.bundlePlan,
      selectedBundleData: state.OrderState.selectedBundleData,
      languagePref: state.OrderState.languagePref,
      generalTerms: state.OrderState.generalTerms,
      selectedProduct: state.OrderState.selectedProduct,
      broadbandEmailError: state.OrderState.broadbandEmailError
    }),
  );
  const { serviceabilityCheck, mrcPosProducts } = useServiceablitityData();
  const { bundlesDetails, plans, bundles } = serviceabilityCheck;
  const dispatch = useDispatch();
  const isBundleVisible = config.SHOW_BUNDLES === 'true' && bundles?.length > 0 && plans.length > 0;
  let { price = 0, equipmentCost = 0, installationCost = 0, techDetail, servRef, setupCost } = internetPlan;
  // check for internet plan added
  const isInternetAdded = Object.keys(internetPlan).length > 0;
  // check for mobile plan added
  const { wirelessPlanInfo = [], wirelesServeRef = '' } = wirelessCartInfo;

  const isMobileAdded = wirelessPlanInfo.length > 0;
  // calculate cart count
  const getWirelessProdCount = wirelessCartInfo?.wirelessPlanInfo?.length ? (wirelessCartInfo.wirelessPlanInfo).reduce((a, { quantity }) => a + quantity, 0) : 0;
  let productCount = Object.keys(internetPlan).length > 0 ? 1 + getWirelessProdCount : getWirelessProdCount;

  // check vas products added
  const vasOrdered = !isUndefinedNullOrEmpty(vasPlanInfo) && Object.keys(vasPlanInfo).length;
  const vasPlan = vasOrdered ? vasPlanInfo.vasPlan : [];
  productCount += vasPlan.length;

  // check for ADSL
  const isADSL = isInternetAdded && techDetail === 'ADSL' && !isUndefinedNullOrEmpty(serviceTerm);
  const modemFee = isInternetAdded ? parseFloat(equipmentCost).toFixed(2) : 0;
  const simCharge = isMobileAdded ? Number(serviceTerm?.filter(val => val?.servLevel === wirelessPlanInfo[0]?.servLevel)[0]?.servShipping) : 0;
  const isWhiAdded = isInternetAdded && internetPlan?.servCode === 'ELFW';
  const isWhiWirelessAdded = isInternetAdded && internetPlan?.servCode === 'ELFW' && (internetPlan?.servLevel === 'ELFW_Unl300G' || internetPlan?.servRef === '522');
  const isVasAdded = vasPlan && vasPlan.length > 0;
  const isBundleAdded = selectedBundleData ? Object.keys(selectedBundleData)?.length > 0 : false;
  const bundleDiscount = isBundleAdded ? Math.abs(Number(selectedBundleData?.creditAmount)) : 0;

  let adslCost = adslShippingCost, vasPrice = 0, adslPromoPrice = 0, oneTimeCharge = 0, monthlyCharge = 0, total = 0, promoPriceValid = false, selectedServX4YPromo = {}, servX4YAmount = '0', servX4YEnd = '', servX4YUnit = 'month';
  const selectedServiceTerm = serviceTerm?.length && serviceTerm?.filter(service => service.servServRef === internetPlan.servRef)[0];
  const ireItems = [];
  const servType = isBundleAdded ? 'Service' : 'mrcPos';

  const chargeMrcPosIpbb = mrcPosProducts?.includes('ipbb');
  let installCost = 0;

  const getMrcPosAmount = Number(selectedServiceTerm?.servX4Y?.find(item => chargeMrcPosIpbb ? item.servType?.toLowerCase() === 'mrcpos' : item.servType?.toLowerCase() === 'install')?.servX4YAmount) || 0;

  let mrcPosMobileAmount = 0;
  const getOneTimeChargesValue = () => {
    if (isWhiAdded) {
      return getValid$Cost(setupCost);
    }
    return getValid$Cost(isBundleAdded ? (Number(oneTimeCharge) - bundleDiscount).toFixed(2) : Number(oneTimeCharge)?.toFixed(2));
  };

  const translateLanguage = languagePref ? TRANSLATE.spanish : TRANSLATE.english;
  const getOneTimeChargesLabel = () => {
    const pathname = '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    if (getMrcPosAmount > 0 && chargeMrcPosIpbb && internetPlan?.servCode === 'ipbb') {
      return `${replaceServiceName(internetPlan.serviceName)}`;
    } else if (isADSL) {
      if (pathname === '/thanks') {
        return translateLanguage.shippingCost;
      } return 'Shipping Cost'
    } else if (isWhiAdded) {
      if (pathname === '/thanks') {
        return translateLanguage.processingAndHandlingFee;
      } return 'Processing & Handling Fee'
    }
    if (pathname === '/thanks') {
      return translateLanguage.installationCost;
    } return 'Installation Cost'
  }

  const getOneTimeCost = () => {
    if (getMrcPosAmount > 0 && chargeMrcPosIpbb && internetPlan?.servCode === 'ipbb') {
      return getValid$Cost(Number(getMrcPosAmount)?.toFixed(2));
    } else if (isADSL) {
      return getValid$Cost(adslCost);
    } else if (isWhiAdded) {
      return getValid$Cost(setupCost);
    }
    return getValid$Cost(Number(installationCost)?.toFixed(2));
  }

  const getMobileLabel = () => {
    const pathname = '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    if (servType === 'mrcPos') {
      if (pathname === '/thanks') {
        return '- ' + translateLanguage.firstMonth;
      }
      return ' - First Month';
    }
  }

  const getMobilePriceLabel = (mobilePlan) => {
    const pathname = '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    return `(${mobilePlan?.quantity}) ${pathname === '/thanks' ? translateLanguage.mobile : 'Mobile'} ${mobilePlan?.serviceName?.replace('Plan', '')?.replace('plan', '')}
     ${getMobileLabel()}`;
  }

  const getMobilePrice = (mobilePlan, page) => {
    let mobilePrice = getPromoPrice(mobilePlan, serviceTerm);
    if (!mobilePrice || (isBundleAdded && page === 'cart')) {
      mobilePrice = getProductPrice(mobilePlan, serviceTerm);
    }
    return getValidIntCost(mobilePrice);
  };

  const InternetBundles = useMemo(() => {
    if (bundlesDetails) {
      return bundlesDetails?.filter(e => e.servCode1?.toLowerCase() === 'ipbb');
    }
  }, [bundlesDetails]);

  const MobileBundles = useMemo(() => {
    if (bundlesDetails) {
      return bundlesDetails?.filter(e => e.servCode1?.toLowerCase() === 'mobile')
    }
  }, [bundlesDetails])
  const getMobileMRCPrce = (mobilePlan) => {
    return Number(serviceTerm?.find(el => el?.servLevel === mobilePlan?.servLevel)?.servX4Y?.find(el => el?.servType.toLowerCase() === servType?.toLowerCase())?.servX4YAmount * mobilePlan?.quantity)?.toFixed(2) || 0
  };
  if (isVasOnlyFlow) {
    if (vasOrdered) {
      if (vasPlanInfo && vasPlanInfo.vasPlan) {
        vasPlanInfo.vasPlan.forEach((vasProd) => {
          vasPrice += getValidIntCost(vasProd.price);
          servRef = vasProd.servRef
        })
      }
    }
    installationCost = 0;
    oneTimeCharge = 0;
    monthlyCharge = vasPrice;
  } else {
    price = getValidIntCost(getProductPrice(internetPlan, serviceTerm) ? getProductPrice(internetPlan, serviceTerm) : 0);
    equipmentCost = getValidIntCost(equipmentCost);
    installationCost = getValidIntCost(installationCost);
    oneTimeCharge = installationCost;
    monthlyCharge = (equipmentCost + price + vasPrice);

    if (isUndefinedNullOrEmpty(adslShippingCost)) {
      adslCost = 19.95;
    }

    // check for promo price
    if (isInternetAdded && !isUndefinedNullOrEmpty(serviceTerm)) {
      selectedServX4YPromo = serviceTerm && selectedServiceTerm?.servX4Y.find(item => item.servType.toLowerCase() === 'promo') || {};
      installCost = Number(
        serviceTerm && selectedServiceTerm?.servX4Y?.find(item => item.servType.toLowerCase() === 'install')?.servX4YAmount || 0,
      );
      oneTimeCharge = getMrcPosAmount > 0 ? getMrcPosAmount : installCost
      installationCost = getMrcPosAmount > 0 ? getMrcPosAmount : installCost
    }

    if (Object.keys(selectedServX4YPromo).length) {
      servX4YAmount = selectedServX4YPromo.servX4YAmount;
      servX4YEnd = selectedServX4YPromo.servX4YEnd;
      servX4YUnit = selectedServX4YPromo.servX4YUnit;

      if (getValidIntCost(servX4YAmount) < price && !isUndefinedNullOrEmpty(servX4YAmount)) {
        price = getValidIntCost(servX4YAmount)
        promoPriceValid = true;
        monthlyCharge = equipmentCost + getValidIntCost(servX4YAmount) + vasPrice;
      }
    }

    if (isADSL || promoPriceValid) {
      adslPromoPrice = `*After ${servX4YEnd}  ${parseInt(servX4YEnd) > 1 ? `${servX4YUnit}s : ${getValid$Cost(internetPlan.price)}` : `: ${getValid$Cost(price)}`}`
      oneTimeCharge = adslCost;
    }

    // check for wireless plans
    if (isMobileAdded) {
      price = price + wirelessPlanInfo.reduce((accum, item) => parseFloat(accum) + parseFloat(item.quantity * getMobilePrice(item, 'cart')), 0);
      mrcPosMobileAmount = wirelessPlanInfo?.reduce((acc, current) => {
        return acc + serviceTerm?.find(val => val?.servLevel === current?.servLevel)?.servX4Y?.find(el => el?.servType?.toLowerCase() === servType?.toLowerCase())?.servX4YAmount * current?.quantity
      }, 0);
      oneTimeCharge += mrcPosMobileAmount > 0 ? mrcPosMobileAmount + simCharge : simCharge;
      monthlyCharge = (equipmentCost + price);
      servRef = !isUndefinedNullOrEmpty(servRef) ? `${servRef},${wirelesServeRef}` : wirelesServeRef;
    }

    // check for vas plans
    // Ire script/ cjPixel vars
    if (vasOrdered) {
      if (vasPlan.length > 0) {
        vasPlan.forEach((vasProd) => {
          let vasArr = {};
          vasPrice += getValidIntCost(vasProd.price);
          servRef += `${!isUndefinedNullOrEmpty(servRef) ? ',' : ''}${vasProd.servRef}`;
          vasArr = {
            subTotal: getValidIntCost(vasProd.price),
            category: vasProd.technology,
            sku: vasProd.servRef,
            quantity: 1,
            name: vasProd.techDetail,
          }
          ireItems.push(vasArr);
        })
        monthlyCharge += vasPrice;
      }
      if (isInternetAdded) {
        ireItems.push({
          subTotal: getValidIntCost(price),
          category: techDetail,
          sku: internetPlan.servRef,
          quantity: 1,
          name: internetPlan.serviceName,
        });
      }
    }
  }
  total = monthlyCharge + oneTimeCharge;
  // new-bundle-code
  const mobileProductServRef = getWirelessServRef(wirelessPlanInfo);

  const allBundlesServRef = [];
  bundlesDetails?.forEach(e => {
    if (e.productsServRef?.includes('ipbb*')) {
      plans?.forEach((ele) => {
        const getServRefArr = e.productsServRef?.toString();
        const replaceIpbbwithSevref = getServRefArr?.replace('ipbb*', ele.servRef) || '';
        allBundlesServRef.push({ code: e.bundleCode, servCodes: replaceIpbbwithSevref });
      });
    }
    if (e.productsServRef?.includes('mobile*')) {
      allBundlesServRef.push({ code: e.bundleCode, servCodes: `mobile${e?.productsServRef?.length}` });
    }
  });
  const updateSelectedBundle = () => {
    if ((isInternetAdded || isVasAdded || isMobileAdded) && isBundleVisible) {
      let bundle = [];
      allBundlesServRef?.forEach(element => {
        const bundleServref = element?.servCodes?.split(',');
        if (
          element?.servCodes === `mobile${wirelessPlanInfo.length}` ||
          element?.servCodes === `mobile${wirelesServeRef?.split(',')?.length}`
        ) {
          const ifExist = bundlesDetails.find(e => e.bundleCode === element.code) || null;
          if (ifExist) {
            bundle.push(ifExist);
            return;
          }
        }
        let result = bundleServref?.every((el) => servRef?.split(',').includes(el)
          && bundleServref?.filter(val => val === el).length
          <=
          servRef?.split(',').filter(val => val === el).length
        );
        if (result) {
          const ifExist = bundlesDetails.find(e => e.bundleCode === element.code) || null;
          bundle.push(ifExist);
        }
      });
      if (bundle?.length > 0) {
        const bundleWithMaxDiscount = bundle?.reduce((prev, current) =>
          Math.abs(Number(prev?.creditAmount)) > Math.abs(Number(current?.creditAmount)) ? prev : current,
        );
        dispatch(setSelectedBundle(bundleWithMaxDiscount));
      } else {
        dispatch(setSelectedBundle(null));
      }
    }
  }
  const getContractLength = (BundleProducts) => {
    let getIbbProduct = BundleProducts?.find((product) => product?.servCode === 'ipbb')
    let contractlen = serviceTerm?.find((service) => service?.servServRef === getIbbProduct?.servRef)
    return contractlen?.servEtfPeriod;
  }
  return {
    isProductsAdded,
    productCount,
    internetPlan,
    wirelessCartInfo,
    vasPlanInfo,
    isInternetAdded,
    isMobileAdded,
    isADSL,
    adslCost,
    installationCost: Number(installationCost)?.toFixed(2),
    modemFee,
    simCharge,
    setupCost,
    isWhiAdded,
    oneTimeCharge: Number(oneTimeCharge)?.toFixed(2),
    monthlyCharge: Number(monthlyCharge)?.toFixed(2),
    total: Number(total)?.toFixed(2),
    adslPromoPrice,
    servRef,
    errorMessage,
    vasPlan,
    isMobileContainer,
    serviceTerm,
    ireItems,
    bundlePlan,
    selectedBundleData,
    bundleDiscount,
    isBundleAdded,
    InternetBundles,
    isVasAdded,
    updateSelectedBundle,
    MobileBundles,
    isBundleVisible,
    getOneTimeChargesValue,
    getOneTimeChargesLabel,
    translateLanguage,
    getOneTimeCost,
    getMobilePrice,
    getMobilePriceLabel,
    mrcPosMobileAmount: (mrcPosMobileAmount).toFixed(2),
    getMrcPosAmount,
    getMobileMRCPrce,
    mobileProductServRef,
    languagePref,
    chargeMrcPosIpbb,
    getContractLength,
    installCost,
    isWhiWirelessAdded,
    selectedProduct,
    generalTerms,
    broadbandEmailError
  };
}

export const useMbox = () => {
  const { isElnkMailboxReq, mboxRequired } = useSelector(
    (state) => ({
      isElnkMailboxReq: state.OrderState.isElnkMailboxReq,
      mboxRequired: state.OrderState.mboxRequired,
    }),
  );

  return {
    isElnkMailboxReq,
    mboxRequired
  };
}

export const useVendor = () => {
  const { internetPlan, extendedInfo } = useSelector(
    (state) => ({
      extendedInfo: state.OrderState.extendedInfo,
      internetPlan: state.OrderState.internetPlan,
    }),
  );

  const vendor = extendedInfo ? extendedInfo.find((result) => result.productId === internetPlan?.servRef)?.vendor : null

  if (vendor?.toLowerCase()?.includes('verizon')) {
    return { vendor: 'Verizon' };
  }

  return {
    vendor: vendor ? vendor.toUpperCase() : ''
  };
};

export const useBusinessAddr = () => {
  const { isBusinessAddress, businessInfo } = useSelector(
    (state) => ({
      isBusinessAddress: state.OrderState.isBusinessAddress,
      businessInfo: state.OrderState.businessInfo
    }),
  );

  return {
    isBusinessAddress,
    businessInfo
  };
};

export const useAccountCheck = () => {
  const { accountCheck, checkingAccount } = useSelector(
    (state) => ({
      accountCheck: state.OrderState.accountCheck,
      checkingAccount: state.OrderState.checkingAccount,
    }),
  );

  return {
    checkAccount: accountCheck,
    checkingAccount
  };
};

export const useScheduleDate = () => {
  const { scheduleDateTime = '' } = useSelector((state) => ({
    scheduleDateTime: state.OrderState.scheduleDateTime,
  }));
  return {
    scheduleDateTime
  };
};

export const useStepsData = () => {
  const { paymentInfo, chgQuestionInfo } = useSelector((state) => ({
    paymentInfo: state.OrderState.paymentInfo,
    chgQuestionInfo: state.OrderState.chgQuestionInfo
  }));
  return {
    paymentInfo,
    chgQuestionInfo
  };
}

export const useLoading = () => {
  const { loading, scheduleLoading, expressLoading, hasError, TCPADisclaimer, broadbandEmailLoading } = useSelector(
    (state) => ({
      loading: state.OrderState.loading,
      scheduleLoading: state.OrderState.scheduleLoading,
      orderSubLoading: state.OrderState.orderSubLoading,
      expressLoading: state.OrderState.expressLoading,
      hasError: state.OrderState.hasError,
      TCPADisclaimer: state.OrderState.TCPADisclaimer,
      broadbandEmailLoading: state.OrderState.broadbandEmailLoading
    }),
  );

  return {
    loading,
    scheduleLoading,
    expressLoading,
    hasError,
    TCPADisclaimer,
    broadbandEmailLoading
  };
};

export const useOrderSubmissionData = () => {
  const { orderSubmitResponse } = useSelector(
    (state) => ({
      orderSubmitResponse: state.OrderState.orderSubmitResponse,
    }),
  );

  return {
    orderSubmitResponse
  };
};

export const useVasData = () => {
  const { vasError, expressVasProducts, isVasOnlyFlow } = useSelector(
    (state) => ({
      expressVasProducts: state.OrderState.expressVasProducts,
      vasError: state.OrderState.vasError,
      isVasOnlyFlow: state.OrderState.isVasOnlyFlow,
    }),
  );

  return {
    vasError,
    expressVasProducts,
    isVasOnlyFlow
  };
};

export const useBuyFlow = () => {
  const { buyflow } = useSelector(
    (state) => ({
      buyflow: state.OrderState.buyflow,
    }),
  );

  return {
    buyflow,
  };
};

export const useCallCenterFlag = () => {
  const { sessionResponse } = useSessionData();
  const { homeUrl = '', agentId } = sessionResponse;
  const getCallCenterTime = config.CALL_CENTER_TIME && config.CALL_CENTER_TIME.split('-');
  const checkTime = () => {
    const date = new Date(); // current time
    const hours = date.getHours();

    return (hours >= parseInt(getCallCenterTime[0]) && hours < parseInt(getCallCenterTime[1]));
  };

  const showPromoStickers = (config.SHOW_CALL_NOW === 'true' && !checkTime() && (homeUrl && ((homeUrl.includes('giftcard') || agentId === 'lp_elnkwww_giftcard') || (homeUrl.includes('inmycity') || agentId === 'lp_clearlink_inmycity') || (homeUrl.includes('members') || agentId === 'lp_mfmedia') || (homeUrl.includes('top10') || agentId === 'lp_mfmedia_top10') || (agentId === 'lp_elnk_home') || (agentId === 'lp_elnk_earthlinkinternet') || (homeUrl.includes('deals') || agentId === 'lp_elnk_deals'))));

  const showCallNow = (config.SHOW_CALL_NOW === 'true' && checkTime() && (homeUrl && ((homeUrl.includes('giftcard') || agentId === 'lp_elnkwww_giftcard') || (homeUrl.includes('inmycity') || agentId === 'lp_clearlink_inmycity') || (homeUrl.includes('members') || agentId === 'lp_mfmedia') || (homeUrl.includes('top10') || agentId === 'lp_mfmedia_top10') || (agentId === 'lp_elnk_home') || (agentId === 'lp_elnk_earthlinkinternet') || (homeUrl.includes('deals') || agentId === 'lp_elnk_deals'))));

  return { showCallNow, showPromoStickers };
};

export const useAgentTfn = (product) => {
  const { sessionResponse, customTFN, TFN, customWhiTFN } = useSessionData();
  const { isEnabled: isDNISDKEnabled, phoneNumber: dniPhoneNumber } = useDNISDK();
  const { serviceabilityCheck } = useServiceablitityData();
  const { fixedWirelessProducts = [] } = serviceabilityCheck;
  const { homeUrl = '', agentId = '' } = sessionResponse;
  if (isDNISDKEnabled && dniPhoneNumber !== '') {
    return formatTFN(dniPhoneNumber)
  } else if (homeUrl.includes('inmycity') && customTFN === '') {
    if (fixedWirelessProducts?.length > 0) {
      return '866-618-0480'
    }
    return '866-906-9434'
  } else if (product === 'whi' && customWhiTFN) {
    return customWhiTFN
  } else if ((homeUrl.includes('inmycity') || homeUrl.includes('members') || homeUrl.includes('top10')) || (homeUrl.includes('giftcard') || (agentId === 'lp_elnkwww_giftcard' && homeUrl.includes('earthlinkinternet')) || (agentId === 'lp_elnk_home' && homeUrl.includes('home')) || (agentId === 'lp_elnk_earthlinkinternet')) && customTFN !== '') {
    return customTFN;
  } else {
    return TFN;
  }
};

export const useValidateAddress = () => {
  const { validateAddress } = useSelector((state) => ({
    validateAddress: state?.OrderState?.validateAddress,
  }));

  return {
    validateAddress,
  };
};
